
import { Component, Vue } from "vue-property-decorator";
import { IUserProfileCreate } from "@/interfaces";
import { dispatchGetUsers, dispatchCreateUser } from "@/store/admin/actions";
import { readToken } from "@/store/main/getters";
import { api } from "@/api";
@Component
export default class CreateUser extends Vue {
  public valid = false;
  public fullName: string = "";
  public email: string = "";
  public isActive: boolean = true;
  public workspaceId: number = 2;
  public setPassword = false;
  public password1: string = "";
  public password2: string = "";

  // CLIENT STUFF
  public clientName: string = "";
  public clientLogo: string = "";
  public redir: string = "";
  public ans: string = "";

  get token() {
    return readToken(this.$store);
  }

  public async createClient() {
    await api
      .createClient(this.token, this.clientName, this.redir, this.clientLogo)
      .then((res) => {
        this.ans = res.data;
      })
      .catch((err) => {
        console.log(err.response.data.detail);
      });
  }

  public async mounted() {
    await dispatchGetUsers(this.$store);
    this.reset();
  }

  public reset() {
    this.password1 = "";
    this.password2 = "";
    this.fullName = "";
    this.email = "";
    this.isActive = true;
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedProfile: IUserProfileCreate = {
        email: this.email,
      };
      if (this.fullName) {
        updatedProfile.full_name = this.fullName;
      }
      if (this.email) {
        updatedProfile.email = this.email;
      }
      updatedProfile.is_active = this.isActive;
      this.workspaceId;
      updatedProfile.password = this.password1;
      await dispatchCreateUser(this.$store, {
        user: updatedProfile,
        workspaceId: this.workspaceId,
      });
      this.$router.push("/main/admin/users");
    }
  }
}
