
import { Component, Vue } from "vue-property-decorator";
import { dispatchGetUsers, dispatchUpdateUser } from "@/store/admin/actions";
import { readToken } from "@/store/main/getters";
import { api } from "@/api";

@Component
export default class EditUser extends Vue {
  public listOfSubscriptions: any[] = [];
  public listOfPlans: any[] = [];

  public async mounted() {
    await dispatchGetUsers(this.$store);
    await this.getSubcriptionData();
    await this.getAllPlans();
  }
  public async getSubcriptionData() {
    const res = await api.getAllSubscribedWorkspaces(this.token, 1);
    this.listOfSubscriptions = res.data;
  }

  public async getAllPlans() {
    const res = await api.getAllPlans(this.token, 1);
    this.listOfPlans = res.data;
  }

  get chartData() {
    const xData: string[] = [];
    const yData: number[] = [];
    const distinctLabels: string[] = [...new Set(this.listOfSubscriptions.map((x) => x.plan))];
    distinctLabels.forEach((k) => {
      const count = this.listOfSubscriptions.filter((obj) => obj.plan === k).length;
      yData.push(count);
      const namedPlan = this.listOfPlans.filter((plan) => k === plan.name);
      xData.push(namedPlan[0].nickname);
    });
    return { x: xData, y: yData, type: "bar", colorscale: "Viridis" };
  }

  get token() {
    return readToken(this.$store);
  }
}
