var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"card--flex-toolbar ma-1",staticStyle:{"border":"2px solid #14eec6"},attrs:{"flat":""}},[_c('v-toolbar',{staticStyle:{"background":"linear-gradient(135deg, rgb(231 252 255), rgb(233 255 235))"},attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" Subscriptions ")])],1),(_vm.listOfPlans.length > 0)?_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":""}},[_c('vue-plotly',{staticStyle:{"width":"100%","position":"relative"},attrs:{"id":"plotly","data":[_vm.chartData],"layout":{
          title: 'Workspaces per plan',
          paper_bgcolor: 'rgba(0,0,0,0)',
          plot_bgcolor: 'rgba(0,0,0,0)',
          barmode: 'stack',
          xaxis: {
            title: {
              text: 'Plan',
            },
            ticks: '',
            showgrid: true,
            zeroline: false,
            showline: true,
            gridcolor: '#ffffff',
            linecolor: '#ffffff',
            gridwidth: 1,
          },
          yaxis: {
            title: {
              text: 'Count',
            },
            ticks: '',
            showgrid: true,
            zeroline: false,
            gridcolor: '#ffffff',
            linecolor: '#ffffff',
            gridwidth: 1,
            showline: true,
          },
        },"display-mode-bar":false,"autoResize":true}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }